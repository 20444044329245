import * as React from 'react';

import {
  Lang,
  Colors,

  Text,
  Icon,
} from '@bluecurrent/web-component-lib';

import Url from '../../../config/Links.config';

export default function ShowAll() {
  const { _ } = Lang.useTranslation();

  return (
    <a
      href={Url().NEWS}
      className="Link"
      style={{
        color: Colors.BLUE,
        height: 40,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Text
          color={Colors.BLUE}
        >
          {_('viewAll', { ns: 'ui' })}
        </Text>
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingTop: 2,
          }}
        >
          <Icon
            name="ArrowRight"
            height={15}
            width={15}
            color={Colors.BLUE}
            iconSet="FA"
          />
        </div>
      </div>
    </a>
  );
}
