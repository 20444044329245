import * as React from 'react';

import {
  Colors,
  Lang,

  useDimensions,

  Title,
  Text,
  Icon,
  Tag,
} from '@bluecurrent/web-component-lib';

import Setup from '../../../config/Setup.config';

export default function Item(props) {
  const size = useDimensions();
  const { _, i18n } = Lang.useTranslation();

  const lang = Setup.PSEUDO ? Setup.FALLBACK_LANG : i18n.language;

  const [isLoading, setLoading] = React.useState(true);
  const [layout, setLayout] = React.useState('vertical');

  React.useEffect(() => {
    setLoading(false);
  }, []);

  React.useEffect(() => {
    setLayout(props.layout);
  }, [size]);

  return (
    <>
      <div
        className={isLoading && (props.data.image === undefined || props.data.image === null) ? 'Skeleton' : undefined}
        style={{
          flex: layout === 'horizontal' ? '0 1 50%' : '0 1 300px',
          backgroundImage: props.data.image !== undefined && `url("${props.data.image}?q=50")`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          minHeight: 300,
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'flex-end',
          gap: 10,
          padding: 10,
        }}
      >
        {
          props.data.tags.map((item) => (
            <div
              /* eslint-disable-next-line no-underscore-dangle */
              key={item._id}
              style={{
                height: 'auto',
              }}
            >
              <Tag
                title={item.title[lang]}
                backgroundColor={item.colors.value}
              />
            </div>
          ))
        }
      </div>
      <div
        style={{
          paddingRight: (props.mirror && layout === 'horizontal') ? 30 : 15,
          paddingLeft: (!props.mirror && layout === 'horizontal') ? 30 : 15,
          paddingBottom: 7.5,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: layout === 'horizontal' ? '50%' : '100%',
        }}
      >
        <div
          style={{
            flex: '1 1 auto',
            paddingTop: 15,
            position: 'relative',
          }}
        >
          <Title
            fontSize="2em"
            heading={3}
          >
            {props.data.title}
          </Title>
          <div
            style={{
              paddingTop: 15,
              paddingBottom: 15,
              position: 'relative',
              maxHeight: 200,
              overflow: 'hidden',
            }}
          >
            <Text
              portableText={!isLoading}
              color={Colors.DARK_GREY}
              lineHeight={1.3}
            >
              {!isLoading && props.data.overview}
            </Text>
            <div
              style={{
                height: 120,
                width: '100%',
                background: 'linear-gradient(180deg, rgba(251,251,251,0) 0%, rgba(251,251,251,0.7007003484987745) 40%, rgba(251,251,251,1) 71%)',
                position: 'absolute',
                bottom: 0,
              }}
            />
          </div>
        </div>
        <div
          style={{
            flex: '0 1 50px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <Text
            color={Colors.BLUE}
          >
            {_('continueReading', { ns: 'ui' })}
          </Text>
          <div
            style={{
              marginLeft: 10,
              paddingTop: 3.5,
              transition: 'margin-left .05s linear',
            }}
          >
            <Icon
              name="ArrowRight"
              height={12.5}
              width={12.5}
              color={Colors.BLUE}
              iconSet="FA"
            />
          </div>
        </div>
      </div>
      <div />
    </>
  );
}
