import * as React from 'react';

import {
  Colors,
  Lang,

  Text,
  Icon,
} from '@bluecurrent/web-component-lib';

import handleLocalUrlChange from '../../../../functions/handleLocalUrlChange';

import Socials from '../../../../config/Socials.config';

export default function Links() {
  const { _ } = Lang.useTranslation();

  return (
    <div
      style={{
        height: 40,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 20,
      }}
    >
      <Text
        fontSize="1.7em"
        fontFamily="Caveat"
      >
        {`${_('followOurAdventure', { ns: 'ui' })}!`}
      </Text>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          paddingTop: 6,
          gap: 7,
        }}
      >
        {
          Socials.map((item, index) => (
            <a
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              href={handleLocalUrlChange(item.url)}
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name={item.icon.name}
                height={25}
                width={25}
                color={Colors.GREY}
                iconSet={item.icon.iconSet}
              />
            </a>
          ))
        }
      </div>
    </div>
  );
}
