import * as React from 'react';

import {
  Colors,

  useDimensions,
} from '@bluecurrent/web-component-lib';

export default function CardWrapper(props) {
  const size = useDimensions();

  const isClickable = (props.data.link || props.isClickable);
  const isHighlighted = isClickable || props.highlight;

  const [isHover, setHover] = React.useState(false);
  const [layout, setLayout] = React.useState('vertical');

  React.useEffect(() => {
    setLayout(props.layout);
  }, [size]);

  return (
    <div
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        display: 'flex',
        flexDirection: layout === 'horizontal'
          ? props.mirror
            ? 'row-reverse'
            : 'row'
          : 'column',
        flex: 1,
        width: '100%',
        border: `solid 2px ${isHighlighted ? Colors.MEDIUM_WHITE : 'transparent'}`,
        borderRadius: isHighlighted ? 10 : 0,
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: isHighlighted ? Colors.LIGHT_GREY : 'transparent',
        boxShadow: (isClickable && isHover) ? `0px 3px 15px 2px ${Colors.DARK_GREY}40` : 'inherit',
        transition: isClickable ? 'box-shadow 0.2s linear' : 'inherit',
      }}
    >
      {props.children}
    </div>
  );
}
