import Url from './Links.config';

export default [
  {
    id: 0,
    url: Url().LINKEDIN,
    icon: {
      name: 'Linkedin',
      iconSet: 'FA',
    },
  },
  {
    id: 1,
    url: Url().INSTAGRAM,
    icon: {
      name: 'Instagram',
      iconSet: 'FA',
    },
  },
];
