import * as React from 'react';

import Banner from './Banner';
import Links from './Links';

export default function Socials(props) {
  const renderSocials = () => {
    switch (props.type) {
      case 'banner':
        return <Banner />;
      default:
        return <Links />;
    }
  };

  return renderSocials();
}
