export default function handleHeadingPrioritization(index) {
  /**
   * handleHeadingPrioritization
   *
   * Formula to handle the heading element type.
   * This defines the prioritization that is handled across the project.
   * Possible options:
   *  1 -> h1
   *  2 -> h2
   */
  return index === 0 ? 1 : 2;
}
