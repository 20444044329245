const getNews = (language) => `
  *[_type == "news" && language == "${language}"] | order(date desc, _updatedAt desc) {
    _id,
    title,
    slug,
    type,
    language,
    "tags": tags[]->,
    overview,
    "image": image.asset->url,
  }
`;

export default getNews;
