import * as React from 'react';

import {
  Colors,
  Lang,

  Text,
  Icon,
  Confetti,
} from '@bluecurrent/web-component-lib';

import handleLocalUrlChange from '../../../../functions/handleLocalUrlChange';

import Socials from '../../../../config/Socials.config';

export default function Banner() {
  const { _ } = Lang.useTranslation();

  return (
    <div
      style={{
        flex: '1 1 auto',
        width: 'calc(33% - 15px)',
        backgroundColor: Colors.BLUE,
        borderRadius: 10,
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 60,
        paddingBottom: 60,
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
        }}
      >
        <Text
          color={Colors.WHITE}
          fontSize="4em"
          fontFamily="Caveat"
        >
          {`${_('followOurAdventure', { ns: 'ui' })}!`}
        </Text>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            gap: 15,
          }}
        >
          {
            Socials.map((item, index) => (
              <a
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                href={handleLocalUrlChange(item.url)}
                target="_blank"
                rel="noreferrer"
              >
                <Icon
                  name={item.icon.name}
                  height={50}
                  width={50}
                  color={Colors.WHITE}
                  iconSet={item.icon.iconSet}
                />
              </a>
            ))
          }
        </div>
      </div>
      <Confetti
        type="DotsPath"
        position="absolute"
        color={Colors.GRADIENT}
        zIndex={0}
        height={1000}
        width={1000}
        right={-100}
        bottom={-200}
        transform="scale(-1)"
        opacity={0.5}
      />
    </div>
  );
}
