import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function SkeletonModule(props) {
  const widthHandler = (index, length) => {
    if (length > 1 && index === 0) return '80%';
    if (index === 1) return '90%';
    if (index === 2) return '60%';
    return props.width ?? '100%';
  };

  return [...Array(props.count ?? 1)].map((item, index) => (
    <React.Fragment
      /* eslint-disable-next-line react/no-array-index-key */
      key={index}
    >
      <Skeleton
        count={1}
        height={props.height ?? 24}
        width={widthHandler(index, props.count ?? 1)}
        style={{
          marginBottom: 4,
          minWidth: 100,
        }}
      />
    </React.Fragment>
  ));
}
