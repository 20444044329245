import * as React from 'react';
import {
  motion,
} from 'framer-motion';

import {
  Lang,
  Breakpoint,
  Colors,

  useDimensions,

  Title,
} from '@bluecurrent/web-component-lib';

import getNews from '../../../services/Sanity/query/getNews';

import useSanityData from '../../../hooks/useSanityData';

import Url from '../../../config/Links.config';

import handleHeadingPrioritization from '../../../functions/handleHeadingPrioritization';

import ContainerHandler from '../../ContainerHandler';

import CardWrapper from '../Cards/CardWrapper';
import Skeleton from '../Skeleton';
import Item from './Item';
import ShowAll from './ShowAll';
import Socials from './Socials';

const Container = ({
  children,
  isPageNews,
}) => {
  if (isPageNews) {
    return (
      <ContainerHandler
        type="ContentContainer"
      >
        {children}
      </ContainerHandler>
    );
  }
  return children;
};

export default function News(props) {
  const { _, i18n } = Lang.useTranslation();
  const size = useDimensions();
  const data = useSanityData(getNews(i18n.language));

  const [useInitialData, setInitialData] = React.useState([]);
  const [useData, setData] = React.useState([]);
  const [usePage, setPage] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setPage({
      pathname: window.location.pathname,
    });
  }, []);

  const isPageNews = usePage.pathname === Url().NEWS;
  const maxItems = isPageNews ? undefined : 3;

  React.useEffect(() => {
    let array = data;

    if (maxItems > 0) {
      array = array.slice(0, 3);
    }

    setInitialData(array);
    if (data !== undefined) setLoading(false);
  }, [data, i18n.language]);

  const layoutHandler = (row, item, index) => {
    if (
      row.length === 1
      && (index + 1) === row.length
      && size.width >= Breakpoint.sm
    ) {
      return 'horizontal';
    }
    return 'vertical';
  };

  const responsive = () => {
    if (
      maxItems !== undefined
      && size.width >= Breakpoint.lg
    ) {
      return {
        layout: maxItems,
        width: `calc(${parseFloat(100 / maxItems)}% - ${(maxItems - 1) * (useData.length === 1 ? 7.5 : 15)}px)`,
      };
    }
    if (size.width < Breakpoint.md) {
      return {
        layout: 1,
        width: '100%',
      };
    }
    return {
      layout: 2,
      width: 'calc(50% - 8px)',
    };
  };

  React.useEffect(() => {
    const array = [];

    for (let i = 0; i < useInitialData.length; i += responsive().layout) {
      array.push(useInitialData.slice(i, i + responsive().layout));
    }

    setData(array);
  }, [useInitialData, size]);

  return (
    <Container
      isPageNews={isPageNews}
    >
      <motion.div
        style={{
          width: '100%',
          height: '100%',
        }}
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
        }}
        transition={{
          duration: 0.5,
          ease: 'easeInOut',
          stiffness: 100,
        }}
        viewport={{
          once: true,
        }}
      >
        <div
          style={{
            marginBottom: 30,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {
            loading ? (
              <Skeleton
                height={40}
                width={200}
              />
            ) : (
              <>
                <div
                  style={{
                    width: isPageNews ? '100%' : '50%',
                  }}
                >
                  <Title
                    textAlign={isPageNews ? 'center' : 'left'}
                    fontSize={isPageNews ? '2.5em' : '2em'}
                    heading={handleHeadingPrioritization(props.params?.index ?? 0)}
                  >
                    {`${_('news', { ns: 'ui' })}${isPageNews ? '' : ':'}`}
                  </Title>
                </div>
                {
                  !isPageNews && (
                    <ShowAll />
                  )
                }
              </>
            )
          }
        </div>
        {
          loading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 15,
              }}
            >
              {
                [...Array(2)].map((e, index) => (
                  <div
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                    style={{
                      width: '50%',
                      height: 'auto',
                      borderRadius: 10,
                      border: `solid 2px ${Colors.MEDIUM_WHITE}`,
                      overflow: 'hidden',
                      backgroundColor: Colors.LIGHT_GREY,
                    }}
                  >
                    <div
                      style={{
                        height: 300,
                        backgroundColor: Colors.LIGHT_WHITE,
                      }}
                    />
                    <div
                      style={{
                        padding: 15,
                      }}
                    >
                      <Skeleton
                        height={40}
                      />
                      <div
                        style={{
                          marginTop: 15,
                        }}
                      >
                        <Skeleton
                          count={3}
                        />
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          ) : useData.map((row, index) => (
            <div
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: responsive().layout > row.length
                  ? 'flex-start'
                  : 'space-between',
                flexWrap: 'wrap',
                gap: 15,
                marginBottom: useData.length !== index + 1 ? 15 : 0,
              }}
            >
              {
                row.map((item, i) => (
                  <a
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={i}
                    href={`${Url().NEWS}/${item.slug}`}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      width: row.length === 2 && row.length < maxItems && useData.length === 1
                        ? 'calc(33% - 15px)'
                        : layoutHandler(row, item, i) === 'horizontal' ? '100%' : responsive().width,
                    }}
                  >
                    <CardWrapper
                      data={item}
                      layout={layoutHandler(row, item, i)}
                      isClickable
                      mirror={
                        (size.width >= Breakpoint.sm && size.width < Breakpoint.md)
                        && index % 2 === 0
                      }
                    >
                      <Item
                        data={item}
                        layout={layoutHandler(row, item, i)}
                        mirror={
                          (size.width >= Breakpoint.sm && size.width < Breakpoint.md)
                          && index % 2 === 0
                        }
                      />
                    </CardWrapper>
                  </a>
                ))
              }
              {
                (row.length === 2 && row.length < maxItems && useData.length === 1) && (
                  <Socials
                    type="banner"
                  />
                )
              }
            </div>
          ))
        }
        {
          (useData.length >= 1 && (useData[0].length >= maxItems || useData.length >= 2)) && (
            <div
              style={{
                marginTop: 15,
              }}
            >
              <Socials />
            </div>
          )
        }
      </motion.div>
    </Container>
  );
}
